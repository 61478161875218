import {
  Box,
  CircularProgress,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ethers } from 'ethers'
import { useSwapDataState } from '../state'
import { getBlockExplorerUrl, getChainId } from '../utils/chainData'

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: '#cacaca',
  },
}))

interface Props {
  hide: boolean
}

export const SwapHistory = ({ hide }: Props) => {
  const classes = useStyles()

  const { swapData, loading } = useSwapDataState()
  const {
    gochainChainId: _gochainChainId,
    ethereumChainId: _ethereumChainId,
    swapItems,
  } = swapData
  const gochainChainId = getChainId(_gochainChainId)
  const ethereumChainId = getChainId(_ethereumChainId)

  if (hide || loading) {
    return null
  }
  if (!swapItems.length) {
    return (
      <Box width="100%" marginTop={3} marginBottom={1}>
        <Typography>No Transaction History</Typography>
      </Box>
    )
  }
  return (
    <Box width="98%" marginTop={3} marginBottom={1}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell style={{ fontWeight: 700 }} align="left">
                Swap ID
              </TableCell>
              <TableCell style={{ fontWeight: 700 }} align="left">
                Transaction Hash
              </TableCell>
              <TableCell style={{ fontWeight: 700 }} align="left">
                From
              </TableCell>
              <TableCell style={{ fontWeight: 700 }} align="left">
                Amount
              </TableCell>
              <TableCell style={{ fontWeight: 700 }} align="left">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              swapItems.map((swap) => (
                <TableRow key={swap.transactionHash}>
                  <TableCell align="left">
                    {swap.fromChain === 'gochain' ? 'GO' : 'WOMI'}
                    {swap.swapId}
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      href={getBlockExplorerUrl(
                        swap.transactionHash,
                        swap.fromChain === 'gochain'
                          ? gochainChainId
                          : ethereumChainId
                      )}
                      target="_blank"
                      rel="noopener"
                    >{`${swap.transactionHash.substring(
                      0,
                      10
                    )}...${swap.transactionHash.substring(
                      swap.transactionHash.length - 8
                    )}`}</Link>
                  </TableCell>
                  <TableCell align="left">
                    <Box display="flex" justifyContent="left" alignItems="left">
                      {swap.fromChain === 'ethereum' && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginRight={1}
                        >
                          <Box>
                            <Box
                              height="100%"
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-end"
                            >
                              <img
                                src="/images/w-omi-top.png"
                                alt="wOMI logo icon"
                                width="20px"
                                height="25px"
                              />
                            </Box>
                          </Box>

                          <Box>
                            <Box
                              height="100%"
                              marginTop={-3}
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                            >
                              <img
                                src="/images/w-omi-bottom.png"
                                alt="wOMI logo icon"
                                width="20px"
                                height="25px"
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                      {swap.fromChain === 'gochain' && (
                        <Box
                          display="flex"
                          justifyContent="left"
                          alignItems="left"
                          marginRight={1}
                        >
                          <img
                            src="/images/omi-red.png"
                            alt="OMI logo icon"
                            width="20px"
                            height="25px"
                          />
                        </Box>
                      )}
                      <Box marginRight={1}>
                        <Typography>
                          {swap.fromChain === 'gochain' ? 'GO OMI' : 'ETH wOMI'}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {swap.toChain === 'gochain' ? (
                      <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="left"
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          marginRight={1}
                        >
                          <Box>
                            <Box
                              height="100%"
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-end"
                            >
                              <img
                                src="/images/w-omi-top.png"
                                alt="wOMI logo icon"
                                width="20px"
                                height="25px"
                              />
                            </Box>
                          </Box>

                          <Box>
                            <Box
                              height="100%"
                              marginTop={-3}
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-start"
                            >
                              <img
                                src="/images/w-omi-bottom.png"
                                alt="wOMI logo icon"
                                width="20px"
                                height="25px"
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Typography>
                          {ethers.utils.commify(
                            ethers.utils.formatUnits(swap.amount, 18)
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="left"
                      >
                        <Box
                          display="flex"
                          justifyContent="left"
                          alignItems="left"
                          marginRight={1}
                        >
                          <img
                            src="/images/eOMI.svg"
                            alt="eOMI logo icon"
                            width="20px"
                            height="25px"
                          />
                        </Box>

                        <Typography>
                          {`${ethers.utils.commify(
                            ethers.utils.formatUnits(swap.amount, 18)
                          )} ETH OMI`}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {swap.distributed
                      ? 'Complete'
                      : swap.hasFee && swap.hasPaidFee
                      ? 'Waiting for Distribution'
                      : swap.hasFee
                      ? 'Waiting for Fee Payment'
                      : 'Waiting for Distrubition'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
