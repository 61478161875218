import { memo, ReactChild } from 'react'
import { createPortal } from 'react-dom'
import '../App.css'

const Modal = memo(
  ({
    children,
    closeModal,
  }: {
    children: ReactChild
    closeModal: () => void
  }) => {
    const domEl = document.getElementById('modal-root')

    if (!domEl) return null

    return createPortal(
      <div className={`modal`}>
        <div className="modal-overlay" onClick={closeModal} />
        <span
          role="button"
          className="modal-close"
          aria-label="close"
          onClick={closeModal}
        >
          x
        </span>
        <div className="modal-body">{children}</div>
      </div>,
      domEl
    )
  }
)

export default Modal
