import { useState, ReactChild } from 'react'

import Modal from '../components/modal'

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false)

  const show = () => setIsVisible(true)
  const hide = () => setIsVisible(false)

  const RenderModal = ({ children }: { children: ReactChild }) => (
    <>{isVisible && <Modal closeModal={hide}>{children}</Modal>}</>
  )

  return {
    show,
    hide,
    RenderModal,
  }
}
