import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modalButton: {
    width: '100%',
    padding: '20px',
    backgroundColor: '#cacaca',
    color: '#444',
    borderRadius: 0,
  },
  modalText: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    margin: '5px 0',
  },
}))

export const ConfirmationModal = ({
  hide,
  handleSubmit,
}: {
  hide: () => void
  handleSubmit: () => Promise<void>
}) => {
  const classes = useStyles()

  return (
    <Card style={{ width: '400px', padding: 0, borderRadius: 20 }}>
      <CardContent style={{ padding: 0 }}>
        <Box width="100%" display="flex" flexDirection="column">
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={3}
            paddingBottom={3}
            bgcolor="#c64e49"
            color="#fff"
          >
            <Typography className={classes.modalText}>
              Confirmation required
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={3}
            color="#444"
          >
            <Typography className={classes.modalText}>
              This OMI chain swap service is 100% user funded.
            </Typography>
            <Typography className={classes.modalText}>
              In order to swap successfully, you will need both GO and ETH to
              cover the gas costs for both chains.
            </Typography>
            <Typography className={classes.modalText}>
              By clicking continue, you acknowledge that you take full
              responsibility for covering these gas costs.
            </Typography>
          </Box>
        </Box>
        <CardActionArea>
          <Box width="100%" display="flex" borderTop="1px solid #616161">
            <Box width="50%">
              <Button className={classes.modalButton} onClick={hide}>
                Cancel
              </Button>
            </Box>
            <Box height="100" width="1px" bgcolor="#616161" />
            <Box width="50%">
              <Button
                className={classes.modalButton}
                onClick={() => {
                  handleSubmit()
                  hide()
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </CardActionArea>
      </CardContent>
    </Card>
  )
}
