import '@fontsource/roboto'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { createWeb3ReactRoot } from '@web3-react/core'
import { ethers } from 'ethers'
import './App.css'
import { Home } from './pages/home'
import { ContextProvider } from './state'

const getMetamaskLibrary = (provider: any): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

const getEthereumLibrary = (provider: any, connector?: AbstractConnector) => {
  if (provider) {
    return provider
  }
  if (connector) {
    return connector.getProvider()
  }
  throw new Error('Invalid ethereum connector configuration')
}

const getGochainLibrary = (provider?: any, connector?: AbstractConnector) => {
  if (provider) {
    return provider
  }
  if (connector) {
    return connector.getProvider()
  }
  throw new Error('Invalid gochain connector configuration')
}

const Web3ReactProviderMetamask = createWeb3ReactRoot('metamask')
const Web3ReactProviderEthereum = createWeb3ReactRoot('ethereum')
const Web3ReactProviderGochain = createWeb3ReactRoot('gochain')

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Condensed, sans-serif',
  },
})

const App = () => (
  <div className="App">
    <ThemeProvider theme={theme}>
      <Web3ReactProviderMetamask getLibrary={getMetamaskLibrary}>
        <Web3ReactProviderEthereum getLibrary={getEthereumLibrary}>
          <Web3ReactProviderGochain getLibrary={getGochainLibrary}>
            <ContextProvider>
              <Home />
            </ContextProvider>
          </Web3ReactProviderGochain>
        </Web3ReactProviderEthereum>
      </Web3ReactProviderMetamask>
    </ThemeProvider>
  </div>
)

export default App
