import {
  Box,
  Button,
  Card,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  ChainId,
  getOppositeChainId,
  getTokenName,
  isEthereum,
} from '../../utils/chainData'

const useStyles = makeStyles((theme) => ({
  closeButton: {
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#cacaca',
    color: '#222',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  progressBar: {
    width: '90%',
  },
}))

export const SwapInProgress = ({
  tokenName,
  chainId,
  currentStep,
  progress,
  swapResult,
  onClose,
}: {
  tokenName: string
  chainId: ChainId
  currentStep: string
  progress: number
  swapResult: string | null
  onClose: () => void
}) => {
  const classes = useStyles()

  const oppositeTokenName = getTokenName(getOppositeChainId(chainId))

  return (
    <>
      <Box display="flex" justifyContent="flex-start" mb={1}>
        <Typography>Swap Status</Typography>
      </Box>
      <Card>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box marginTop={3} marginBottom={3}>
            <Typography>{`${tokenName} to ${
              isEthereum(chainId) ? 'ETH OMI' : oppositeTokenName
            } swap ${
              swapResult
                ? swapResult === 'success'
                  ? 'success'
                  : 'failed'
                : 'in progress'
            }`}</Typography>
          </Box>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={3}
          >
            {isEthereum(chainId) ? (
              <Box display="flex" flexDirection="column">
                <Box>
                  <Box
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                  >
                    <img
                      src="/images/w-omi-top.png"
                      alt="wOMI logo icon"
                      width="100px"
                      height="120px"
                    />
                  </Box>
                </Box>

                <Box>
                  <Box
                    height="100%"
                    marginTop={-15}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    <img
                      src="/images/w-omi-bottom.png"
                      alt="wOMI logo icon"
                      width="100px"
                      height="120px"
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                <img
                  src="/images/omi-red.png"
                  alt="OMI logo icon"
                  width="100px"
                  height="120px"
                />
              </Box>
            )}
            <Box marginLeft={2} marginRight={2}>
              {!swapResult ? (
                <img
                  src="/images/convert.png"
                  alt="convert"
                  width="50px"
                  height="63px"
                />
              ) : swapResult === 'success' ? (
                <img
                  src="/images/success-icon.png"
                  alt="success"
                  width="38px"
                  height="36px"
                />
              ) : (
                <img
                  src="/images/fail-icon.png"
                  alt="failure"
                  width="38px"
                  height="36px"
                />
              )}
            </Box>
            <Box>
              <img
                src="/images/omi-red.png"
                alt="OMI logo icon"
                width="100px"
                height="120px"
              />
            </Box>
          </Box>
        </Box>

        {!swapResult && (
          <Box
            width="100%"
            paddingTop={2}
            paddingBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={'#cacaca'}
          >
            <Box>
              <Typography variant="body1" gutterBottom>
                {currentStep}
              </Typography>
            </Box>
            <LinearProgress
              className={classes.progressBar}
              color="primary"
              variant="determinate"
              value={progress}
            />
          </Box>
        )}

        {swapResult === 'fail' && (
          <Button
            className={classes.closeButton}
            fullWidth
            variant="contained"
            onClick={onClose}
          >
            {'TRY AGAIN'}
          </Button>
        )}
      </Card>
    </>
  )
}
