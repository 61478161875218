import { Box, Container, Typography } from '@material-ui/core'
import { ethers } from 'ethers'
import { useSwapDataState } from '../../state'
import {
  getChainBaseName,
  getChainBasePrintName,
  getOppositeChainId,
} from '../../utils/chainData'

export const ConnectedInstructions = () => {
  const { currentChainId, distributionFee, hasUnpaidDistribution } =
    useSwapDataState().swapData

  const currentChainName = getChainBaseName(currentChainId)

  return (
    <Container>
      <Box
        bgcolor="#424242"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={3}
        mt={3}
        style={{
          borderRadius: '5px',
        }}
      >
        <Box width="100%" textAlign="left">
          <Typography variant="body1" style={{ fontWeight: 700 }} gutterBottom>
            {`${
              currentChainName === 'ethereum' && hasUnpaidDistribution
                ? 'Fee Payment'
                : 'Swap'
            } Instructions`}
          </Typography>
        </Box>
        {!hasUnpaidDistribution && (
          <Box width="100%" textAlign="left" mb={2}>
            <Typography variant="body2" gutterBottom>
              {`Trying to swap ${
                currentChainName === 'ethereum'
                  ? 'GO OMI to ETH OMI'
                  : 'ETH wOMI to ETH OMI'
              }? Change MetaMask to the ${getChainBasePrintName(
                getOppositeChainId(currentChainId)
              )} network first.`}
            </Typography>
          </Box>
        )}
        {currentChainName === 'gochain' && (
          <Box width="100%" textAlign="left" mb={2}>
            <Typography variant="body2">
              If you are ready to get started, fill out and submit the swap
              form. After submitting you will be asked to sign two transactions:
            </Typography>
            <Typography variant="body2">
              (1) approve the swap contract to use your GO OMI balance
            </Typography>
            <Typography variant="body2">
              (2) initiate the swap in the swap contract
            </Typography>
          </Box>
        )}
        {currentChainName === 'gochain' && distributionFee && (
          <Box width="100%" textAlign="left">
            <Typography variant="body2" gutterBottom>
              {`To cover ETH gas costs for distribution, after swapping you will need to switch chains in MetaMask to the Ethereum network and send a transaction with ${ethers.utils.formatEther(
                distributionFee
              )} ETH to fund the distribution.`}
            </Typography>
          </Box>
        )}
        {currentChainName === 'gochain' && (
          <Box width="100%" textAlign="left">
            <Typography variant="body2" gutterBottom>
              Your ETH OMI will be distributed to your account after 50 block
              confirmations.
            </Typography>
          </Box>
        )}
        {currentChainName === 'ethereum' && hasUnpaidDistribution && (
          <Box width="100%" textAlign="left" mb={2}>
            <Typography variant="body2" gutterBottom>
              Click pay fee. Your ETH OMI will be distributed to your account
              after 50 block confirmations.
            </Typography>
          </Box>
        )}
        {currentChainName === 'ethereum' && !hasUnpaidDistribution && (
          <Box width="100%" textAlign="left" mb={2}>
            <Typography variant="body2">
              If you are ready to get started, fill out and submit the swap
              form. After submitting you will be asked to sign two transactions:
            </Typography>
            <Typography variant="body2">
              (1) approve the swap contract to use your ETH wOMI balance
            </Typography>
            <Typography variant="body2">
              (2) initiate the swap in the swap contract
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  )
}
