import { Box, Container, Typography } from '@material-ui/core'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { isGochain } from '../utils/chainData'
import { throwIfUndefined } from '../utils/errors'

export const SwapDirection = () => {
  const { chainId: _chainId } =
    useWeb3React<ethers.providers.Web3Provider>('metamask')
  const chainId = throwIfUndefined(_chainId)

  return (
    <Container>
      <Box display="flex" justifyContent="flex-start" marginBottom="9px">
        <Typography>Swap Direction</Typography>
      </Box>
      <Box>
        {isGochain(chainId) ? (
          <img
            src="/images/swap-gOMI-to-eOMI.png"
            alt="OMI logo icon"
            width="500px"
            height="115px"
          />
        ) : (
          <img
            src="/images/swap-wOMI-to-eOMI.png"
            alt="wOMI logo icon"
            width="500px"
            height="115px"
          />
        )}
      </Box>
    </Container>
  )
}
