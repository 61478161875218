import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { ChainSwapForm } from '../components/chainSwap'
import { ConnectMetamask } from '../components/connect'
import { ErrorBanner } from '../components/errorBanner'
import { Footer } from '../components/footer'
import { Header } from '../components/header'
import { ConnectedInstructions } from '../components/instructions/connectedInstructions'
import { DisconnectedInstructions } from '../components/instructions/disconnectedInstructions'
import { ConnectedSubHeader } from '../components/subheader/connectedSubheader'
import { SwapDirection } from '../components/swapDirection'
import { SwapHistory } from '../components/swapHistory'
import { useSwapDataState } from '../state'
import { injectedConnector } from '../utils/connectors'
import { getErrorMessage } from '../utils/errors'
import { useEagerConnect } from '../utils/hooks'

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1100px',
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
  },
  box: {
    width: '100%',
    padding: '10px',
  },
}))

export const Home = () => {
  const [activatingConnector, setActivatingConnector] =
    useState<AbstractConnector>()
  const [showHistory, setShowHistory] = useState(false)
  const [inAppErrors, setInAppErrors] = useState<string[]>([])
  const [settingUpApp, setSettingUpApp] = useState(true)
  const loading = useSwapDataState().loading
  const classes = useStyles()

  useEffect(() => {
    const timeout = setTimeout(() => setSettingUpApp(false), 2000)
    return () => clearTimeout(timeout)
  }, [])

  const { connector, active, deactivate, error } =
    useWeb3React<ethers.providers.Web3Provider>('metamask')

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  useEagerConnect()

  return (
    <>
      <Header />
      {active && <ConnectedSubHeader />}

      <Container className={classes.container}>
        <Box display="flex" alignItems="center" flexDirection="column" mt={5}>
          {inAppErrors.length ? (
            <ErrorBanner errors={inAppErrors} setErrors={setInAppErrors} />
          ) : null}
          {settingUpApp || (active && loading) ? (
            <Box
              width="100%"
              height="500px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <LinearProgress style={{ width: '50%' }} />
            </Box>
          ) : (
            <>
              <Grid container>
                <Grid item xs={6}>
                  <Box className={classes.box}>
                    {!active && !error && (
                      <>
                        <ConnectMetamask
                          activating={activatingConnector === injectedConnector}
                          setActivatingConnector={setActivatingConnector}
                        />
                      </>
                    )}

                    {error && (
                      <>
                        <Typography variant="h6" gutterBottom>
                          Error Connecting to Metamask
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {getErrorMessage(error)}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={deactivate}
                        >
                          Reset
                        </Button>
                      </>
                    )}

                    {active && (
                      <ChainSwapForm
                        errors={inAppErrors}
                        setErrors={setInAppErrors}
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.box}
                  >
                    {active && <SwapDirection />}
                    {active ? (
                      <ConnectedInstructions />
                    ) : (
                      <DisconnectedInstructions />
                    )}
                  </Box>
                </Grid>
              </Grid>

              {active && <SwapHistory hide={showHistory} />}
            </>
          )}
          <Footer />
        </Box>
      </Container>
    </>
  )
}
