import {
  Container,
  Box,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  textContainer: {
    margin: '0 0 0 10px',
  },
  titleText: {
    margin: '8px 0 0 0',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '26px',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    lineHeight: '1.15',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#fff',
  },
  blueText: {
    margin: '30px 0 0 0',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#5ecced',
  },
  inline: {
    display: 'inline-block',
    fontWeight: 300,
    color: '#fff',
  },
  downloadButton: {
    margin: '30px 0 0',
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#5ecced',
    color: '#9c9c9c',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  downloadText: {
    color: '#2c2c2c',
    textDecoration: 'none',
    fontFamily: 'Roboto Condensed, sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    lineHeight: '1.94',
    letterSpacing: 'normal',
  },
}))

export const DisconnectedInstructions = () => {
  const classes = useStyles()
  return (
    <Container>
      <Box
        bgcolor="#424242"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={3}
        style={{ borderRadius: '5px' }}
      >
        <Box
          width="100%"
          textAlign="left"
          mb={3}
          className={classes.textContainer}
        >
          <Typography
            className={classes.titleText}
            variant="body1"
            gutterBottom
          >
            OMI Token Swap Instructions
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.blueText}
            >{`Check you are using the official URL`}</Typography>
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.inline}
              style={{ whiteSpace: 'break-spaces' }}
            >
              {'https://chainswap.ecomi.com/'}
            </Typography>
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.blueText}
            >{`To swap GO OMI to ETH OMI`}</Typography>
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.inline}
              style={{ whiteSpace: 'break-spaces' }}
            >
              {'Set Metamask to the '}
            </Typography>
            <Typography
              className={classes.inline}
              style={{ textDecoration: 'underline' }}
              component="span"
            >
              Gochain network
            </Typography>
          </Typography>
          <Typography component="div" gutterBottom>
            <Typography variant="body2">{`(chain id: 60)`}</Typography>{' '}
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.blueText}
            >{`To swap ETH wOMI to ETH OMI`}</Typography>{' '}
          </Typography>
          <Typography component="div" variant="body2" gutterBottom>
            <Typography
              className={classes.inline}
              style={{ whiteSpace: 'break-spaces' }}
            >
              {`Set Metamask to the `}
            </Typography>
            <Typography
              className={classes.inline}
              style={{ textDecoration: 'underline' }}
              component="span"
            >
              Ethereum network
            </Typography>
          </Typography>
          <Typography component="div" gutterBottom>
            <Typography variant="body2">{`(chain id: 1)`}</Typography>
          </Typography>
        </Box>
        <Button
          className={classes.downloadButton}
          fullWidth
          variant="contained"
        >
          <a
            className={classes.downloadText}
            href="https://ecomi.com"
            target="_blank"
            rel="noreferrer"
          >
            See the official How To Guide
          </a>
        </Button>
      </Box>
    </Container>
  )
}
