import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import {
  EthereumDistribute__factory,
  GochainSwap__factory,
  SimpleERC20Token__factory,
  WOMISwapToOMI__factory,
} from '../types/contracts'
import { braidArrays } from '../utils/braidArrays'
import {
  ChainId,
  ContractName,
  getChainId,
  getContractAddress,
  getContractDeploymentBlock,
} from '../utils/chainData'
import { throwIfUndefined } from '../utils/errors'

export interface SwapData {
  metamaskLibrary: ethers.providers.Web3Provider | undefined
  currentAccount: string
  currentChainId: ChainId
  gochainChainId: ChainId
  ethereumChainId: ChainId

  gochainBalance: ethers.BigNumber
  gochainOmiBalance: ethers.BigNumber

  ethereumBalance: ethers.BigNumber
  ethereumOmiBalance: ethers.BigNumber
  ethereumWomiBalance: ethers.BigNumber

  distributionFee: ethers.BigNumber
  hasUnpaidDistribution: boolean
  unpaidDistributionSwapIds: ethers.BigNumber[]
  gochainMinumimSwapAmount: ethers.BigNumber
  womiMinimumSwapAmount: ethers.BigNumber
  swapItems: SwapItem[]
}
interface SwapItem {
  swapId: string
  fromChain: 'ethereum' | 'gochain'
  toChain: 'ethereum' | 'gochain'
  direction: 'To Ethereum' | 'To Gochain'
  transactionHash: string
  blockNumber: number
  amount: ethers.BigNumber
  swapConfirmations: number
  hasFee: boolean
  hasPaidFee?: boolean
  feePaidConfirmations?: number
  distributed: boolean
}

export const useSwapData = (
  loading: boolean,
  setLoading: (loading: boolean) => void,
  setSwapData: (swapData: SwapData) => void
) => {
  const [swapItems, setSwapItems] = useState<SwapItem[]>([])
  const [distributionFee, setDistributionFee] = useState(
    ethers.BigNumber.from(0)
  )
  const [hasUnpaidDistribution, setHasUnpaidDistribution] =
    useState<boolean>(false)
  const [unpaidDistributionSwapIds, setUnpaidDistributionSwapIds] = useState<
    ethers.BigNumber[]
  >([])
  const [gochainMinumimSwapAmount, setGochainMinumimSwapAmount] = useState(
    ethers.BigNumber.from(0)
  )
  const [womiMinumimSwapAmount, setWomiMinumimSwapAmount] = useState(
    ethers.BigNumber.from(0)
  )
  const [gochainBalance, setGochainBalance] = useState(ethers.BigNumber.from(0))
  const [gochainOmiBalance, setGochainOmiBalance] = useState(
    ethers.BigNumber.from(0)
  )
  const [ethereumBalance, setEthereumBalance] = useState(
    ethers.BigNumber.from(0)
  )
  const [ethereumOmiBalance, setEthereumOmiBalance] = useState(
    ethers.BigNumber.from(0)
  )
  const [ethereumWomiBalance, setEthereumWomiBalance] = useState(
    ethers.BigNumber.from(0)
  )

  const {
    library: _metamaskLibrary,
    chainId: _currentChainId,
    account: _currentAccount,
  } = useWeb3React<ethers.providers.Web3Provider>('metamask')
  const metamaskLibrary = throwIfUndefined(_metamaskLibrary)
  const currentAccount = throwIfUndefined(_currentAccount)
  const currentChainId = getChainId(_currentChainId)
  const { library: _gochainLibrary, chainId: _gochainChainId } =
    useWeb3React<ethers.providers.Web3Provider>('gochain')
  const gochainLibrary = throwIfUndefined(_gochainLibrary)
  const gochainChainId = getChainId(_gochainChainId)
  const { library: _ethereumLibrary, chainId: _ethereumChainId } =
    useWeb3React<ethers.providers.Web3Provider>('ethereum')
  const ethereumLibrary = throwIfUndefined(_ethereumLibrary)
  const ethereumChainId = getChainId(_ethereumChainId)

  useEffect(() => {
    setSwapData({
      metamaskLibrary,
      currentAccount,
      currentChainId,
      gochainBalance,
      gochainOmiBalance,
      ethereumBalance,
      ethereumOmiBalance,
      ethereumWomiBalance,
      gochainChainId,
      ethereumChainId,
      distributionFee: distributionFee,
      hasUnpaidDistribution: hasUnpaidDistribution,
      unpaidDistributionSwapIds: unpaidDistributionSwapIds,
      gochainMinumimSwapAmount,
      womiMinimumSwapAmount: womiMinumimSwapAmount,
      swapItems,
    })
    const timeout = setTimeout(() => setLoading(false), 2000)
    return () => clearTimeout(timeout)
  }, [
    loading,
    setLoading,
    metamaskLibrary,
    currentAccount,
    currentChainId,
    gochainBalance,
    gochainOmiBalance,
    ethereumBalance,
    ethereumOmiBalance,
    ethereumWomiBalance,
    gochainChainId,
    ethereumChainId,
    distributionFee,
    hasUnpaidDistribution,
    unpaidDistributionSwapIds,
    gochainMinumimSwapAmount,
    womiMinumimSwapAmount,
    swapItems,
    setSwapData,
  ])

  useEffect(() => {
    const gochainSwapContract = GochainSwap__factory.connect(
      getContractAddress({
        chainId: gochainChainId,
        contractName: ContractName.SWAP,
      }),
      gochainLibrary
    )
    const ethereumDistributeContract = EthereumDistribute__factory.connect(
      getContractAddress({
        chainId: ethereumChainId,
        contractName: ContractName.DISTRIBUTE,
      }),
      ethereumLibrary
    )

    const fetchGochainSwaps = async () =>
      gochainSwapContract.queryFilter(
        gochainSwapContract.filters.Swap(
          null,
          _.isUndefined(currentAccount) ? null : currentAccount,
          null,
          null,
          null,
          null
        ),
        getContractDeploymentBlock({
          chainId: gochainChainId,
          contractName: ContractName.SWAP,
        })
      )

    const fetchEthereumDistributions = async () =>
      ethereumDistributeContract.queryFilter(
        ethereumDistributeContract.filters.Distribute(
          null,
          _.isUndefined(currentAccount) ? null : currentAccount,
          null,
          null,
          null,
          null
        ),
        getContractDeploymentBlock({
          chainId: ethereumChainId,
          contractName: ContractName.DISTRIBUTE,
        })
      )

    const gochainOmiContract = SimpleERC20Token__factory.connect(
      getContractAddress({
        chainId: gochainChainId,
        contractName: ContractName.OMI,
      }),
      gochainLibrary
    )

    const ethereumOmiContract = SimpleERC20Token__factory.connect(
      getContractAddress({
        chainId: ethereumChainId,
        contractName: ContractName.OMI,
      }),
      ethereumLibrary
    )

    const ethereumWomiContract = SimpleERC20Token__factory.connect(
      getContractAddress({
        chainId: ethereumChainId,
        contractName: ContractName.WOMI,
      }),
      ethereumLibrary
    )

    const ethereumWomiSwapContract = WOMISwapToOMI__factory.connect(
      getContractAddress({
        chainId: ethereumChainId,
        contractName: ContractName.WOMI_SWAP,
      }),
      ethereumLibrary
    )

    const fetchWomiSwaps = async () =>
      ethereumWomiSwapContract.queryFilter(
        ethereumWomiSwapContract.filters.Swap(
          null,
          _.isUndefined(currentAccount) ? null : currentAccount,
          null,
          null
        ),
        getContractDeploymentBlock({
          chainId: ethereumChainId,
          contractName: ContractName.WOMI_SWAP,
        })
      )

    Promise.all([
      gochainLibrary.getBalance(currentAccount),
      ethereumLibrary.getBalance(currentAccount),
      gochainOmiContract.balanceOf(currentAccount),
      ethereumOmiContract.balanceOf(currentAccount),
      ethereumWomiContract.balanceOf(currentAccount),
      gochainLibrary.getBlockNumber(),
      ethereumLibrary.getBlockNumber(),
      ethereumDistributeContract.fee(),
      gochainSwapContract.minimumSwapAmount(),
      ethereumWomiSwapContract.minimumSwapAmount(),
    ]).then(
      async ([
        gochainBalance,
        ethereumBalance,
        gochainOmiBalance,
        ethereumOmiBalance,
        ethereumWomiBalance,
        gochainBlockNumber,
        ethereumBlockNumber,
        ethereumDistributionFee,
        gochainMinumimSwapAmount,
        womiMinumimSwapAmount,
      ]) => {
        const [gochainSwapEvents, distributeEvents, womiSwapEvents] =
          await Promise.all([
            fetchGochainSwaps(),
            fetchEthereumDistributions(),
            fetchWomiSwaps(),
          ])

        const distributionMap = distributeEvents.reduce<{
          [key: string]: boolean
        }>(
          (map, { args }) => ({
            ...map,
            [`GO${args.swapId.toString()}`]: true,
          }),
          {}
        )
        const hasPaidFeeMap: {
          [swapId: string]: boolean
        } = {}
        await Promise.all(
          gochainSwapEvents.map(async (s) => {
            if (ethereumDistributionFee.eq(0)) {
              return [s.args.swapId.toString(), false]
            }
            const hasPaidFee = await ethereumDistributeContract.getHasPaidFee(
              s.args.swapId.toString()
            )
            if (!hasPaidFee) {
              setHasUnpaidDistribution(true)
              setUnpaidDistributionSwapIds((existing) => [
                ...existing,
                s.args.swapId,
              ])
            }
            hasPaidFeeMap[s.args.swapId.toString()] = hasPaidFee
          })
        )

        const lastTenGochainSwaps = gochainSwapEvents.slice(
          Math.max(gochainSwapEvents.length - 10, 0)
        )
        const gochainSwapItems = lastTenGochainSwaps
          .map<SwapItem>((swap) => ({
            swapId: swap.args.swapId.toString(),
            fromChain:
              swap.args.fromChain === 'gochain' ? 'gochain' : 'ethereum',
            toChain: swap.args.toChain === 'gochain' ? 'gochain' : 'ethereum',
            direction:
              swap.args.toChain === 'ethereum' ? 'To Ethereum' : 'To Gochain',
            transactionHash: swap.transactionHash,
            blockNumber: swap.blockNumber,
            amount: swap.args.amount,
            swapConfirmations:
              (swap.args.fromChain === 'gochain'
                ? gochainBlockNumber
                : ethereumBlockNumber) - swap.blockNumber,
            hasFee: ethereumDistributionFee.gt(0),
            hasPaidFee: hasPaidFeeMap[swap.args.swapId.toString()],
            distributed: !!distributionMap[`GO${swap.args![0].toString()}`],
          }))
          .sort((a, b) => b.blockNumber - a.blockNumber)
        const lastTenWomiSwaps = womiSwapEvents.slice(
          Math.max(womiSwapEvents.length - 10, 0)
        )
        const womiSwapItems = lastTenWomiSwaps
          .map<SwapItem>((swap) => ({
            swapId: swap.args.swapId.toString(),
            fromChain: 'ethereum',
            toChain: 'ethereum',
            direction: 'To Ethereum',
            transactionHash: swap.transactionHash,
            blockNumber: swap.blockNumber,
            amount: swap.args.amount,
            swapConfirmations: ethereumBlockNumber - swap.blockNumber,
            hasFee: ethereumDistributionFee.gt(0),
            hasPaidFee: hasPaidFeeMap[swap.args.swapId.toString()],
            distributed: !!distributionMap[`GO${swap.args![0].toString()}`],
          }))
          .sort((a, b) => b.blockNumber - a.blockNumber)

        unstable_batchedUpdates(() => {
          setGochainBalance(gochainBalance)
          setEthereumBalance(ethereumBalance)
          setGochainOmiBalance(gochainOmiBalance)
          setEthereumOmiBalance(ethereumOmiBalance)
          setEthereumWomiBalance(ethereumWomiBalance)
          setDistributionFee(ethereumDistributionFee)
          setGochainMinumimSwapAmount(gochainMinumimSwapAmount)
          setWomiMinumimSwapAmount(womiMinumimSwapAmount)
          setSwapItems(braidArrays(womiSwapItems, gochainSwapItems)) // This option to avoid having to get the block timestamp for every swap
        })
      }
    )
    // Ensure this runs only once
  }, [
    currentAccount,
    ethereumChainId,
    ethereumLibrary,
    gochainChainId,
    gochainLibrary,
    currentChainId,
  ])
}
