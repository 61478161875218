import {
  AppBar,
  Box,
  Button,
  Grid,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { getChainPrintName } from '../utils/chainData'
import { getErrorMessage } from '../utils/errors'

const useStyles = makeStyles((theme) => ({
  chainName: {
    verticalAlign: 'super',
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: '#2c2c2c',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disconnectButton: {
    backgroundColor: '#fc4242',
    color: '#fff',
  },
}))

const ConnectionInfoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#7a7a7a',
    color: '#fff',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const ConnectionStatusIcon = withStyles({
  colorAction: {
    color: 'green',
  },
  colorError: {
    color: 'red',
  },
  colorDisabled: {
    color: 'grey',
  },
})(FiberManualRecord)

export const Header = () => {
  const { active, error, chainId, account, deactivate } =
    useWeb3React<ethers.providers.Web3Provider>('metamask')
  const classes = useStyles()

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar}>
        <Grid item xs={12} sm={2}>
          <Box
            marginLeft={1}
            height="100%"
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src="/images/veve-logo.png"
              alt="Veve logo"
              width="120px"
              height="30px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {account && (
              <Box mr={1}>
                <Typography variant="body2">{`Address: ${account}`}</Typography>
              </Box>
            )}
            <Box>
              <ConnectionInfoTooltip
                title={
                  <>
                    <Typography variant="body1" color="inherit" gutterBottom>
                      {!!error
                        ? 'Error'
                        : active
                        ? 'Connected'
                        : 'Disconnected'}
                    </Typography>
                    {active && (
                      <>
                        <Typography variant="body2" gutterBottom>
                          Chain ID: {chainId}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Account:{' '}
                          {account === null
                            ? '-'
                            : account
                            ? `${account.substring(0, 6)}...${account.substring(
                                account.length - 4
                              )}`
                            : ''}
                        </Typography>
                      </>
                    )}
                    {error && (
                      <Typography variant="body2" gutterBottom>
                        {getErrorMessage(error)}
                      </Typography>
                    )}
                    {(active || !!error) && (
                      <Button
                        variant="contained"
                        className={classes.disconnectButton}
                        onClick={deactivate}
                      >
                        Disconnect
                      </Button>
                    )}
                  </>
                }
                interactive
              >
                <Box>
                  <ConnectionStatusIcon
                    color={active ? 'action' : error ? 'error' : 'disabled'}
                  />
                  {chainId && (
                    <Typography
                      display="inline"
                      className={classes.chainName}
                      variant="body1"
                    >
                      {getChainPrintName(chainId)}
                    </Typography>
                  )}
                </Box>
              </ConnectionInfoTooltip>
            </Box>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
