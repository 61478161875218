import { IState } from '.'

export enum ActionTypes {
  SET_LOADING = 'SET_LOADING',
  UPDATE_SWAP_DATA = 'UPDATE_SWAP_DATA',
}

export const reducer = (
  state: IState,
  action: { type: ActionTypes; payload: any }
) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return { ...state, loading: action.payload }
    }
    case ActionTypes.UPDATE_SWAP_DATA:
      return { ...state, swapData: { ...action.payload } }

    default:
      return state
  }
}
