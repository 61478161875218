import { AppBar, Box, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { ethers } from 'ethers'
import { useSwapData } from '../../hooks/useSwapData'
import { useSwapDataDispatch, useSwapDataState } from '../../state'

const useStyles = makeStyles((theme) => ({
  chainName: {
    verticalAlign: 'super',
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    background: '#424242',
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cryptoLabel: {
    color: '#0ecef2',
  },
  inline: {
    display: 'inline',
  },
  boldText: {
    fontWeight: 700,
    display: 'inline',
  },
}))

export const ConnectedSubHeader = () => {
  const classes = useStyles()
  const { setLoading, updateSwapData } = useSwapDataDispatch()
  const { loading, swapData } = useSwapDataState()
  const {
    currentChainId,
    gochainOmiBalance,
    gochainBalance,
    ethereumBalance,
    ethereumOmiBalance,
    ethereumWomiBalance,
  } = swapData

  useSwapData(loading, setLoading, updateSwapData)

  if (loading || !currentChainId) {
    return null
  }

  const formatTokenAmount = (balance: string) => {
    const numberWithCommas = (x: string) => {
      const parts = x.split('.')
      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
        (parts[1] ? '.' + parts[1] : '')
      )
    }

    const balanceNoCommas = balance
      .split('')
      .filter((char) => char !== ',')
      .join('')
    const balanceToNumber = +balanceNoCommas
    const fixedBalance = balanceToNumber.toFixed(10)
    const trimmedBalance = (+fixedBalance).toString()
    return numberWithCommas(trimmedBalance)
  }

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar className={classes.toolBar}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            align-items="center"
          >
            <Box display="flex" alignItems="center" mr={2}>
              <Box mr={1}>
                <img
                  src="/images/omi.png"
                  alt="Omi logo icon"
                  width="19px"
                  height="24px"
                />
              </Box>
              <Box marginRight={1}>
                <Typography>
                  {loading
                    ? '...'
                    : ethers.utils.commify(
                        ethers.utils.formatUnits(gochainOmiBalance, 18)
                      )}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cryptoLabel}>GO OMI</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center" mr={2}>
              <Box mr={1}>
                <img
                  src="/images/w-omi.png"
                  alt="wOMI logo icon"
                  width="19px"
                  height="23px"
                />
              </Box>
              <Box marginRight={1}>
                <Typography>
                  {loading
                    ? '...'
                    : ethers.utils.commify(
                        ethers.utils.formatUnits(ethereumWomiBalance, 18)
                      )}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cryptoLabel}>
                  ETH wOMI
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center" mr={2}>
              <Box mr={1}>
                <img
                  src="/images/eOMI-white.svg"
                  alt="eOMI logo icon"
                  width="19px"
                  height="23px"
                />
              </Box>
              <Box marginRight={1}>
                <Typography>
                  {loading
                    ? '...'
                    : ethers.utils.commify(
                        ethers.utils.formatUnits(ethereumOmiBalance, 18)
                      )}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cryptoLabel}>ETH OMI</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center" mr={2}>
              <Box mr={1}>
                <img
                  src="/images/go-chain.png"
                  alt="Go Chain logo icon"
                  width="22px"
                  height="22px"
                />
              </Box>
              <Box marginRight={1}>
                <Typography>
                  {loading
                    ? '...'
                    : formatTokenAmount(
                        ethers.utils.commify(
                          ethers.utils.formatEther(gochainBalance)
                        )
                      )}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cryptoLabel}>GO</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <img
                  src="/images/eth.png"
                  alt="Eth logo icon"
                  width="14px"
                  height="23px"
                />
              </Box>
              <Box marginRight={1}>
                <Typography>
                  {loading
                    ? '...'
                    : formatTokenAmount(
                        ethers.utils.commify(
                          ethers.utils.formatEther(ethereumBalance)
                        )
                      )}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cryptoLabel}>ETH</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
