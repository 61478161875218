import { Box, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export const Footer = () => {
  return (
    <Box marginTop={4} marginBottom={5}>
      <Grid className="Footer" container justify="center">
        <Typography
          variant="subtitle2"
          align="center"
          component="div"
          style={{
            color: '#bbbcbc',
            fontFamily: 'Roboto Condensed, sans-serif',
          }}
        >
          By using this service you agree to the{' '}
          <a
            style={{ color: '#bbbcbc' }}
            href="https://www.veve.me/terms-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms &amp; Conditions
          </a>
        </Typography>
      </Grid>
    </Box>
  )
}
