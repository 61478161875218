import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useEffect } from 'react'
import useSWR, { SWRConfiguration } from 'swr'
import { abiMap } from './chainData'
import {
  ethereumConnector,
  gochainConnector,
  injectedConnector,
} from './connectors'
import { throwIfUndefined } from './errors'

export class ABIError extends Error {}

export class ABINotFound extends Error {}

export const fetcher =
  (library: ethers.providers.Web3Provider, ABIs?: Map<string, any>) =>
  (
    // @ts-ignore
    ...args
  ) => {
    const [arg1, arg2, ...params] = args.slice(1)
    // it's a contract
    if (ethers.utils.isAddress(arg1)) {
      if (!ABIs) throw new ABIError(`ABI repo not found`)
      if (!ABIs.get) throw new ABIError(`ABI repo isn't a Map`)
      const address = arg1
      const method = arg2
      const abi = ABIs.get(address)
      if (!abi) throw new ABINotFound(`ABI not found for ${address}`)
      const contract = new ethers.Contract(address, abi, library)
      const _params = params
      if (method === 'queryFilter') {
        _params[0] = JSON.parse(params[0])
      }
      return contract[method](..._params)
    }

    // it's an eth call
    const method = arg1
    // @ts-ignore
    return library[method](arg2, ...params)
  }

export const useWeb3SWR = <R = any, E = any>(
  args: [provider: 'metamask' | 'gochain' | 'ethereum', ...args: any],
  options?: SWRConfiguration<R, E>
) => {
  const provider = args[0]
  const { library: _library } =
    useWeb3React<ethers.providers.Web3Provider>(provider)
  const library = throwIfUndefined(_library)
  return useSWR<R, E>(args, {
    fetcher: fetcher(library, abiMap),
    revalidateOnFocus: false,
    ...options,
  })
}

export function useEagerConnect() {
  const { activate: metamaskActivate, active: metamaskActive } =
    useWeb3React<ethers.providers.Web3Provider>('metamask')
  const { activate: gochainActivate, active: gochainActive } =
    useWeb3React<ethers.providers.Web3Provider>('gochain')
  if (!gochainActive) {
    gochainActivate(gochainConnector)
  }
  const { activate: ethereumActivate, active: ethereumActive } =
    useWeb3React<ethers.providers.Web3Provider>('ethereum')
  if (!ethereumActive) {
    ethereumActivate(ethereumConnector)
  }

  useEffect(() => {
    injectedConnector.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized && !metamaskActive) {
        metamaskActivate(injectedConnector, undefined, true).catch(() => {})
      }
    })
  }, [metamaskActivate, metamaskActive])
}
