import { Box, makeStyles, Typography } from '@material-ui/core'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  errorText: {
    fontWeight: 700,
  },
}))

interface Props {
  errors: string[]
  setErrors: (errors: string[]) => void
}

export const ErrorBanner = ({ errors, setErrors }: Props) => {
  const classes = useStyles()

  useEffect(() => {
    if (errors.length > 1) {
      const cycleErrors = setTimeout(() => setErrors(errors.slice(1)), 3000)
      return () => clearTimeout(cycleErrors)
    }
  }, [errors, setErrors])

  return (
    <Box
      width="98%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="#d64343"
      paddingTop={2}
      paddingBottom={2}
      marginBottom={3}
      borderRadius={3}
    >
      <Box>
        <Typography className={classes.errorText}>{errors[0]}</Typography>
      </Box>
    </Box>
  )
}
