import { InjectedConnector } from '@web3-react/injected-connector'
import { ChainId } from './chainData'
import { NetworkConnector } from './networkConnector'

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    ChainId.ETHEREUM_MAINNET,
    ChainId.ETHEREUM_TESTNET,
    ChainId.GOCHAIN_MAINNET,
    ChainId.GOCHAIN_TESTNET,
  ].map((id) => Number(id)),
})

export const gochainConnector = new NetworkConnector({
  urls: {
    60: 'https://rpc.gochain.io:443',
    31337: 'https://testnet-rpc.gochain.io',
  },
  // TODO: Change this before production deployment
  defaultChainId: 31337,
})

export const ethereumConnector = new NetworkConnector({
  urls: {
    1: 'https://mainnet.infura.io/v3/3cb45774c19e48fab8c36ebceab2c194',
    4: 'https://rinkeby.infura.io/v3/3cb45774c19e48fab8c36ebceab2c194',
  },
  // TODO: Change this before production deployment
  defaultChainId: 4,
})
