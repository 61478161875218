import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { ethers } from 'ethers'
import { ChangeEvent } from 'react'
import { ChainId, isEthereum } from '../../utils/chainData'

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 30,
    marginTop: 10,
  },
  convertContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  convertContent: {
    flex: 1,
    padding: 0,
    margin: 0,
  },
  inputContainer: {
    margin: 0,
    padding: 10,
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
    flexDirection: 'column',
  },
  swapData: {
    padding: 10,
  },
  swapButton: {
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginTop: '20px',
    backgroundColor: '#2c2c2c',
    color: '#0ecef2',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  historyButton: {
    marginTop: '20px',
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#424242',
    color: '#9c9c9c',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  swapInputText: {
    fontSize: '28px',
    fontFamily: 'Roboto Condensed, sans-serif',
  },
  text: {
    fontWeight: 300,
    fontFamily: 'Roboto Condensed, sans-serif',
  },
}))

export const SwapForm = ({
  chainId,
  tokenName,
  tokenBalance,
  minimumSwapAmount,
  distributionFee,
  amountToSwap,
  onChangeSwapAmount,
  receivingAddress,
  onChangeReceivingAddress,
  showHistory,
  onSetShowHistory,
  validating,
  errors,
  onSubmit,
}: {
  chainId: ChainId
  tokenName: string
  tokenBalance?: ethers.BigNumber
  minimumSwapAmount: ethers.BigNumber
  distributionFee?: ethers.BigNumber
  amountToSwap: string
  onChangeSwapAmount: (event: ChangeEvent<HTMLInputElement>) => void
  receivingAddress: string
  onChangeReceivingAddress: (event: ChangeEvent<HTMLInputElement>) => void
  showHistory: boolean
  onSetShowHistory: (showHistory: boolean) => void
  validating: boolean
  errors: string[]
  onSubmit: () => Promise<void>
}) => {
  const classes = useStyles()

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Typography
          style={{
            fontWeight: 700,
            whiteSpace: 'break-spaces',
          }}
        >{`${tokenName} `}</Typography>
        <Typography>Swap Amount</Typography>
      </Box>
      <Card className={classes.card}>
        <CardActionArea className={classes.convertContainer}>
          <CardContent className={classes.convertContent}>
            <Grid
              justify="space-between"
              container
              spacing={1}
              className={classes.inputContainer}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                flex="1"
              >
                {isEthereum(chainId) ? (
                  <Box display="flex" flexDirection="column" marginRight={1}>
                    <Box>
                      <Box
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                      >
                        <img
                          src="/images/w-omi-top.png"
                          alt="wOMI logo icon"
                          width="20px"
                          height="25px"
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        height="100%"
                        marginTop={-3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                      >
                        <img
                          src="/images/w-omi-bottom.png"
                          alt="wOMI logo icon"
                          width="20px"
                          height="25px"
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <img
                      src="/images/icon-OMI.svg"
                      width="30px"
                      height="30px"
                      alt="womi"
                    />
                  </Box>
                )}
                <TextField
                  id="standard-full-width"
                  className={classes.text}
                  placeholder={`Amount ${tokenName}`}
                  error={
                    amountToSwap === '' &&
                    errors.includes('Enter the amount to swap')
                  }
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    classes: {
                      input: classes.swapInputText,
                    },
                    disableUnderline: true,
                  }}
                  disabled={validating}
                  value={amountToSwap}
                  onChange={onChangeSwapAmount}
                />
              </Box>
            </Grid>
            <hr style={{ marginTop: 0 }} />

            <Grid
              justify="space-between"
              alignItems="flex-end"
              container
              spacing={1}
              className={classes.swapData}
            >
              <Grid item>
                <Typography
                  variant="subtitle2"
                  component="span"
                  color="textSecondary"
                  className={classes.text}
                >
                  Balance:{' '}
                  {tokenBalance
                    ? ethers.utils.commify(
                        ethers.utils.formatUnits(tokenBalance, 18)
                      )
                    : '...'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding={1}
        border="1px solid #222"
        bgcolor="#393939"
      >
        {distributionFee && distributionFee?.gt(0) && (
          <Typography variant="body2" style={{ margin: '0.35em' }}>
            {`Distribution Fee: ${ethers.utils.formatEther(
              distributionFee
            )} ETH`}
          </Typography>
        )}
        {minimumSwapAmount?.gt(0) && (
          <Typography variant="body2" style={{ margin: '0.35em' }}>
            Minimum Swap Amount:{' '}
            {minimumSwapAmount
              ? `${ethers.utils.formatUnits(minimumSwapAmount, 18)}`
              : '...'}
          </Typography>
        )}
      </Box>
      <Box marginTop="40px" textAlign="left">
        <Typography variant="body1">Receiving Ethereum Address</Typography>
      </Box>
      <Card className={classes.card}>
        <CardActionArea>
          <CardContent>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <TextField
                id="standard-full-width"
                fullWidth
                placeholder="Paste your address here"
                value={receivingAddress}
                disabled={validating}
                onChange={onChangeReceivingAddress}
                error={
                  receivingAddress === '' &&
                  errors.includes('Enter your receiving address')
                }
              />
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>

      <Button
        className={classes.swapButton}
        fullWidth
        disabled={validating}
        variant="contained"
        onClick={onSubmit}
      >
        {`Swap ${tokenName}`}
      </Button>
      <Button
        className={classes.historyButton}
        fullWidth
        variant="contained"
        onClick={() => onSetShowHistory(!showHistory)}
      >
        View Swap History
      </Button>
    </>
  )
}
