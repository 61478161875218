import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { injectedConnector } from '../utils/connectors'

const useStyles = makeStyles((theme) => ({
  connectButton: {
    marginBottom: '10px',
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#2c2c2c',
    color: '#0ecef2',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  downloadButton: {
    borderRadius: '5px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#424242',
    color: '#9c9c9c',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  downloadText: {
    color: '#9c9c9c',
    textDecoration: 'none',
  },
  metamaskText: {
    fontSize: '30px',
    fontWeight: 700,
    color: '#9c9c9c',
  },
}))

export const ConnectMetamask = (props: {
  activating: boolean
  setActivatingConnector: (connector: AbstractConnector) => void
}) => {
  const { activate } = useWeb3React<ethers.providers.Web3Provider>('metamask')

  const classes = useStyles()

  return (
    <Box width="100%" marginBottom={4}>
      <Box
        width="100%"
        height="350px"
        marginBottom={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bgcolor="white"
        style={{ borderRadius: '5px' }}
      >
        <Box>
          <img
            src="/images/metamask.png"
            alt="wOMI logo icon"
            width="200px"
            height="200px"
          />
        </Box>
        <Box>
          <Typography className={classes.metamaskText} variant="body1">
            METAMASK
          </Typography>
        </Box>
      </Box>
      <Button
        className={classes.connectButton}
        fullWidth
        disabled={props.activating}
        variant="contained"
        onClick={() => {
          props.setActivatingConnector(injectedConnector)
          activate(injectedConnector)
        }}
      >
        {props.activating ? 'Connecting' : 'Connect To Metamask'}
      </Button>
      <Button className={classes.downloadButton} fullWidth variant="contained">
        <a
          className={classes.downloadText}
          href="https://metamask.io/download"
          target="_blank"
          rel="noreferrer"
        >
          Download Metamask
        </a>
      </Button>
    </Box>
  )
}
