import { ethers } from 'ethers'
import {
  createContext,
  Dispatch,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { SwapData } from '../hooks/useSwapData'
import { ChainId } from '../utils/chainData'
import { ActionTypes, reducer } from './reducer'

export interface IState {
  loading: boolean
  swapData: SwapData
}

const initialContext: IState = {
  loading: true,
  swapData: {
    metamaskLibrary: undefined,
    currentAccount: '',
    currentChainId: ChainId.UNDEFINED,
    gochainChainId: ChainId.UNDEFINED,
    ethereumChainId: ChainId.UNDEFINED,
    gochainBalance: ethers.BigNumber.from(0),
    gochainOmiBalance: ethers.BigNumber.from(0),
    ethereumBalance: ethers.BigNumber.from(0),
    ethereumOmiBalance: ethers.BigNumber.from(0),
    ethereumWomiBalance: ethers.BigNumber.from(0),
    gochainMinumimSwapAmount: ethers.BigNumber.from(0),
    womiMinimumSwapAmount: ethers.BigNumber.from(0),
    distributionFee: ethers.BigNumber.from(0),
    hasUnpaidDistribution: false,
    unpaidDistributionSwapIds: [],
    swapItems: [],
  },
}

const StateContext = createContext(initialContext)
const DispatchContext = createContext<Dispatch<any> | undefined>(undefined)

export const ContextProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useSwapDataState = () => {
  return useContext(StateContext)
}

export const useSwapDataDispatch = () => {
  const dispatch = useContext(DispatchContext)

  if (dispatch === undefined) {
    throw new Error('No dispatch context')
  }

  const setLoading = useCallback(
    (loading: boolean) => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: loading })
    },
    [dispatch]
  )

  const updateSwapData = useCallback(
    (swapData: SwapData) => {
      dispatch({ type: ActionTypes.UPDATE_SWAP_DATA, payload: swapData })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setLoading,
      updateSwapData,
    }),
    [updateSwapData, setLoading]
  )
}
