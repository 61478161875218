import {
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ethers } from 'ethers'
import { ChainId, getChainBaseName } from '../../utils/chainData'

const useStyles = makeStyles((theme) => ({
  fundButton: {
    borderRadius: 0,
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#2c2c2c',
    color: '#0ecef2',
    fontFamily: 'Roboto Condensed, sans-serif',
    textTransform: 'none',
  },
  progressBar: {
    width: '90%',
  },
}))

export const PayFee = ({
  chainId,
  tokenName,
  ethereumBalance,
  hasUnpaidDistribution,
  distributionFee,
  currentStep,
  progress,
  onSubmit,
  payingFee,
  payingFeeSuccess,
}: {
  chainId: ChainId
  tokenName: string
  ethereumBalance: ethers.BigNumber
  hasUnpaidDistribution: boolean
  distributionFee: ethers.BigNumber
  currentStep: string
  progress: number
  onSubmit: () => Promise<void>
  payingFee: boolean
  payingFeeSuccess: boolean
}) => {
  const classes = useStyles()

  const mustChangeNetworks =
    getChainBaseName(chainId) === 'gochain' && hasUnpaidDistribution

  const hasSufficientBalance =
    getChainBaseName(chainId) === 'ethereum' &&
    ethereumBalance.gt(distributionFee)

  return (
    <>
      <Box display="flex" justifyContent="flex-start" mb={1}>
        <Typography>Swap Status</Typography>
      </Box>
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: '20px' }}
          >
            {payingFeeSuccess ? (
              <Box marginTop={3} marginBottom={3}>
                <Typography variant="h3">{`Fee successfully paid!`}</Typography>
              </Box>
            ) : (
              <>
                <Box marginTop={3} marginBottom={3}>
                  <Typography variant="h5">
                    Distribution Funding Needed
                  </Typography>
                </Box>

                {mustChangeNetworks && (
                  <Box marginTop={3} marginBottom={3}>
                    <Typography variant="h6">{`Please change network to Ethereum to continue`}</Typography>
                  </Box>
                )}

                {!mustChangeNetworks && !hasSufficientBalance && (
                  <Box marginTop={3} marginBottom={3}>
                    <Typography style={{ color: '#f25434', fontWeight: 700 }}>
                      {`Insufficient ETH. Please top up and refresh the
                    page to fund the distribution`}
                    </Typography>
                  </Box>
                )}

                {!mustChangeNetworks && hasSufficientBalance && (
                  <Box marginTop={3} marginBottom={3}>
                    <Typography
                      style={{ marginLeft: '3px', marginRight: '5px' }}
                    >
                      {getChainBaseName(chainId) === 'gochain' && (
                        <img
                          src="/images/gochainlogo.png"
                          alt="gochain logo icon"
                          width="25px"
                          height="25px"
                        />
                      )}
                      {getChainBaseName(chainId) === 'ethereum' && (
                        <img
                          src="/images/ethereumlogo.png"
                          alt="ethereum logo icon"
                          width="20px"
                          height="25px"
                        />
                      )}
                      <Typography component="span" variant="h4">
                        {`${ethers.utils.formatEther(distributionFee)} ETH`}
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </Box>
        </CardContent>
        {payingFee && (
          <Box
            width="100%"
            paddingTop={2}
            paddingBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor={'#cacaca'}
          >
            <Box>
              <Typography variant="body1" gutterBottom>
                {currentStep}
              </Typography>
            </Box>
            <LinearProgress
              className={classes.progressBar}
              color="primary"
              variant="determinate"
              value={progress}
            />
          </Box>
        )}
        {!payingFeeSuccess && (
          <Button
            className={classes.fundButton}
            fullWidth
            variant="contained"
            disabled={mustChangeNetworks || payingFee}
            onClick={onSubmit}
          >
            Pay Fee
          </Button>
        )}
      </Card>
    </>
  )
}
