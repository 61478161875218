import { ethers } from 'ethers'
import { ChangeEvent, useEffect, useState } from 'react'
import { useModal } from '../../hooks/useModal'
import { useSwapDataState } from '../../state'
import {
  EthereumDistribute__factory,
  GochainSwap__factory,
  SimpleERC20Token__factory,
  WOMISwapToOMI__factory,
} from '../../types/contracts'
import {
  ContractName,
  getContractAddress,
  getOppositeChainId,
  getTokenName,
  isEthereum,
  isGochain,
} from '../../utils/chainData'
import { isPositiveNumericString } from '../../utils/validation'
import { wait } from '../../utils/wait'
import { ConfirmationModal } from './confirmationModal'
import { PayFee } from './payFee'
import { SwapForm } from './swapForm'
import { SwapInProgress } from './swapInProgress'

export const ChainSwapForm = ({
  errors,
  setErrors,
  showHistory,
  setShowHistory,
}: {
  errors: string[]
  setErrors: (errors: string[]) => void
  showHistory: boolean
  setShowHistory: (show: boolean) => void
}) => {
  const { loading, swapData } = useSwapDataState()
  const {
    metamaskLibrary,
    currentAccount,
    currentChainId,
    ethereumBalance,
    gochainOmiBalance,
    ethereumWomiBalance,
    gochainMinumimSwapAmount,
    womiMinimumSwapAmount: womiMinumimSwapAmount,
    hasUnpaidDistribution,
    unpaidDistributionSwapIds,
    distributionFee,
  } = swapData

  const { show, hide, RenderModal } = useModal()

  const [amountToSwap, setAmountToSwap] = useState<string>('')
  const handleChangeSwapAmount = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value === '' ||
      isPositiveNumericString(event.target.value)
    ) {
      setAmountToSwap(event.target.value)
    }
  }

  const [receivingAddress, setReceivingAddress] =
    useState<string>(currentAccount)
  const handleChangeReceivingAddress = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event.target.value === '' ||
      ethers.utils.isAddress(event.target.value)
    ) {
      setReceivingAddress(event.target.value)
    }
  }

  const [currentStep, setCurrentStep] = useState('')
  const [progress, setProgress] = useState(0)

  const [validating, setValidating] = useState(false)
  const [swapping, setSwapping] = useState(false)
  const [swapResult, setSwapResult] = useState<string | null>(null)
  const [payingFee, setPayingFee] = useState(false)
  const [payingFeeSuccess, setPayingFeeSuccess] = useState(false)

  useEffect(() => {
    return () => setErrors([])
  }, [setErrors])

  if (loading || !metamaskLibrary) {
    return null
  }

  const tokenName = getTokenName(currentChainId)

  const omiContractAddress = getContractAddress({
    chainId: currentChainId,
    contractName: ContractName.OMI,
  })
  const omiContract = SimpleERC20Token__factory.connect(
    omiContractAddress,
    metamaskLibrary.getSigner()
  )

  const gochainSwapContractAddress = getContractAddress({
    chainId: isGochain(currentChainId)
      ? currentChainId
      : getOppositeChainId(currentChainId),
    contractName: ContractName.SWAP,
  })
  const gochainSwapContract = GochainSwap__factory.connect(
    gochainSwapContractAddress,
    metamaskLibrary.getSigner()
  )

  const distributeContractAddress = getContractAddress({
    chainId: isEthereum(currentChainId)
      ? currentChainId
      : getOppositeChainId(currentChainId),
    contractName: ContractName.DISTRIBUTE,
  })
  const distributeContract = EthereumDistribute__factory.connect(
    distributeContractAddress,
    metamaskLibrary.getSigner()
  )

  const womiContractAddress = getContractAddress({
    chainId: isEthereum(currentChainId)
      ? currentChainId
      : getOppositeChainId(currentChainId),
    contractName: ContractName.WOMI,
  })
  const womiContract = SimpleERC20Token__factory.connect(
    womiContractAddress,
    metamaskLibrary.getSigner()
  )

  const womiSwapContractAddress = getContractAddress({
    chainId: isEthereum(currentChainId)
      ? currentChainId
      : getOppositeChainId(currentChainId),
    contractName: ContractName.WOMI_SWAP,
  })
  const womiSwapContract = WOMISwapToOMI__factory.connect(
    womiSwapContractAddress,
    metamaskLibrary.getSigner()
  )

  const refreshPage = () => {
    setTimeout(() => window.location.reload(), 1500)
  }

  const validateErrors = async () => {
    setValidating(true)
    const errorMessages: string[] = []
    if (amountToSwap === '') {
      errorMessages.push('Enter the amount to swap')
    }
    if (receivingAddress === '') {
      errorMessages.push('Enter your receiving address')
    }
    const chainBalance = await metamaskLibrary.getBalance(
      currentAccount,
      'latest'
    )
    if (!chainBalance || ethers.BigNumber.from(chainBalance).isZero()) {
      errorMessages.push(`Insufficient GO to cover gas fees`)
    }
    const omiBalance = await omiContract.balanceOf(currentAccount)
    if (
      amountToSwap &&
      omiBalance &&
      ethers.utils.parseUnits(amountToSwap, 18).gt(omiBalance)
    ) {
      errorMessages.push(`Insufficient ${tokenName} to swap`)
    }
    if (errorMessages.length > 0) {
      setErrors(errorMessages)
      setValidating(false)
      return
    }
    setErrors([])
    setValidating(false)
    if (isGochain(currentChainId)) {
      show()
    }
    if (isEthereum(currentChainId)) {
      await handleWomiSwapSubmit()
    }
  }

  const handleGochainSwapSubmit = async () => {
    setSwapping(true)
    setProgress(0)
    try {
      setCurrentStep('Checking allowance')
      setProgress(25)
      const fetchAllowance = async () =>
        omiContract.allowance(currentAccount, gochainSwapContractAddress)
      let allowance: ethers.BigNumber = await fetchAllowance()
      if (allowance.lt(ethers.utils.parseUnits(amountToSwap, 18))) {
        setCurrentStep(
          `Requesting to increase allowance by ${ethers.utils.formatUnits(
            ethers.utils.parseUnits(amountToSwap, 18).sub(allowance),
            18
          )} ${tokenName}`
        )
        const allowanceResponse = await omiContract.increaseAllowance(
          gochainSwapContractAddress,
          ethers.utils.parseUnits(amountToSwap, 18).sub(allowance).toHexString()
        )
        setCurrentStep('Waiting for increased allowance')
        setProgress(50)
        await allowanceResponse.wait(1)
        while (allowance.lt(ethers.utils.parseUnits(amountToSwap, 18))) {
          allowance = await fetchAllowance()
          await wait(1500)
        }
      }

      setCurrentStep(`Requesting to swap ${amountToSwap} ${tokenName}`)
      setProgress(75)
      const swap = await gochainSwapContract.swap(
        receivingAddress,
        ethers.utils.parseUnits(amountToSwap, 18).toHexString()
      )
      setCurrentStep('Waiting for swap')
      await swap.wait(1)

      setCurrentStep(`Submitted swap for ${amountToSwap} GO OMI to ETH Omi`)
      setProgress(100)
      await wait(3000)
      setProgress(0)
      setSwapResult('success')
      refreshPage()
    } catch (error) {
      setErrors(['Something went wrong while swapping tokens.'])
      setProgress(0)
      setSwapResult('fail')
    }
  }

  const handleWomiSwapSubmit = async () => {
    setSwapping(true)
    setProgress(0)
    try {
      setCurrentStep('Checking allowance')
      setProgress(25)
      const fetchAllowance = async () =>
        womiContract.allowance(currentAccount, womiSwapContractAddress)
      let allowance: ethers.BigNumber = await fetchAllowance()
      if (allowance.lt(ethers.utils.parseUnits(amountToSwap, 18))) {
        setCurrentStep(
          `Requesting to increase allowance by ${ethers.utils.formatUnits(
            ethers.utils.parseUnits(amountToSwap, 18).sub(allowance),
            18
          )} ${tokenName}`
        )
        const allowanceResponse = await womiContract.increaseAllowance(
          womiSwapContractAddress,
          ethers.utils.parseUnits(amountToSwap, 18).sub(allowance).toHexString()
        )
        setCurrentStep('Waiting for increased allowance')
        setProgress(50)
        await allowanceResponse.wait(1)
        while (allowance.lt(ethers.utils.parseUnits(amountToSwap, 18))) {
          allowance = await fetchAllowance()
          await wait(1500)
        }
      }

      setCurrentStep(`Requesting to swap ${amountToSwap} ${tokenName}`)
      setProgress(75)
      const swap = await womiSwapContract.swap(
        receivingAddress,
        ethers.utils.parseUnits(amountToSwap, 18).toHexString()
      )
      setCurrentStep('Waiting for swap')
      await swap.wait(1)

      setCurrentStep(`Submitted swap for ${amountToSwap} wOMI to ETH Omi`)
      setProgress(100)
      await wait(3000)
      setSwapResult('success')
      refreshPage()
    } catch (error) {
      setErrors(['Something went wrong while swapping tokens.'])
      setProgress(0)
      setSwapResult('fail')
    }
  }

  const handlePayFeeSubmit = async () => {
    setErrors([])
    setPayingFee(true)
    try {
      setProgress(35)
      setCurrentStep(
        `Requesting to send ${ethers.utils.formatEther(distributionFee)} ETH`
      )

      const earliestUnpaidSwapId = unpaidDistributionSwapIds[0]
      const payFee = await distributeContract.payFee(earliestUnpaidSwapId, {
        value: distributionFee.toString(),
      })
      setProgress(70)
      setCurrentStep(`Waiting for fee payment`)
      await payFee.wait(1)
      setCurrentStep('')
      setProgress(100)
      setPayingFeeSuccess(true)
      await wait(3000)
      refreshPage()
    } catch (error) {
      setProgress(0)
      setPayingFee(false)
      setErrors(['Something went wrong with the fee payment.'])
    }
  }

  const handleResultClose = () => {
    setSwapResult(null)
    setSwapping(false)
    setErrors([])
  }

  if (hasUnpaidDistribution || payingFee) {
    return (
      <PayFee
        chainId={currentChainId}
        hasUnpaidDistribution={hasUnpaidDistribution}
        distributionFee={distributionFee}
        ethereumBalance={ethereumBalance}
        tokenName={tokenName}
        currentStep={currentStep}
        progress={progress}
        onSubmit={handlePayFeeSubmit}
        payingFee={payingFee}
        payingFeeSuccess={payingFeeSuccess}
      />
    )
  }

  return (
    <>
      {!swapping && (
        <SwapForm
          chainId={currentChainId}
          tokenName={tokenName}
          tokenBalance={
            isGochain(currentChainId) ? gochainOmiBalance : ethereumWomiBalance
          }
          minimumSwapAmount={
            isGochain(currentChainId)
              ? gochainMinumimSwapAmount
              : womiMinumimSwapAmount
          }
          distributionFee={
            isGochain(currentChainId) ? distributionFee : undefined
          }
          amountToSwap={amountToSwap}
          onChangeSwapAmount={handleChangeSwapAmount}
          receivingAddress={receivingAddress}
          onChangeReceivingAddress={handleChangeReceivingAddress}
          showHistory={showHistory}
          onSetShowHistory={setShowHistory}
          validating={validating}
          errors={errors}
          onSubmit={validateErrors}
        />
      )}

      {swapping && (
        <SwapInProgress
          tokenName={tokenName}
          chainId={currentChainId}
          currentStep={currentStep}
          progress={progress}
          swapResult={swapResult}
          onClose={handleResultClose}
        />
      )}

      <RenderModal>
        <ConfirmationModal hide={hide} handleSubmit={handleGochainSwapSubmit} />
      </RenderModal>
    </>
  )
}
